import momenttz from 'moment-timezone';
import Api from 'Shared/Services/Api/index';
import GetSystemAuthUtil from 'Shared/Utils/ConfigEnvironment/GetSystemAuth/index';
// import { data } from '../../Mock/TargetSupplier/index';
import { IHomeTargetsSupplier, IHomeTargetsSupplierFilters, IHomeTargetsSupplierResponse } from '../../Interface/HomeTargetsSupplier';
import convertToNumber from 'Shared/Utils/ConvertToNumber';
import sortBy from 'sort-by';

const validFilters = (element: IHomeTargetsSupplier, filters: IHomeTargetsSupplierFilters) => {
  let isFilter = true
  if (filters.codigo && Number(element.codigo) !== Number(filters.codigo)) {
    isFilter = false
  }
  if (filters.documento && element.documento !== filters.documento) {
    isFilter = false
  }
  if (filters.nome && !element.nome?.includes(filters.nome)) {
    isFilter = false
  }
  if (filters.nomeFornec && !element.nomeFornec?.includes(filters.nomeFornec)) {
    isFilter = false
  }
  return isFilter;
}


export const getTargetSupplierService = async (params: IHomeTargetsSupplierFilters) => {
  return new Promise<IHomeTargetsSupplierResponse>(async (resolve, reject) => {
    let _arrayReg: [IHomeTargetsSupplier?] = [];
    let regReturn: IHomeTargetsSupplierResponse = {
      status: '200',
      message: '',
    };

    try {
      let _url = params.codigo ? `/metabyassociado/${Number(params.codigo).toString()}` :
        params.codClientePrinc ? `/metabyassociadoprincipal/${Number(params.codClientePrinc).toString()}` : '/meta';

      let _config = {
        "systemauth": GetSystemAuthUtil(process.env.REACT_APP_ENVIRONMENT)
      };
      await Api.get(_url, { params: _config })
        .then(resp => {
          let _arrayResume: [IHomeTargetsSupplier?] = [];
          let _arrayResumeProvider: [IHomeTargetsSupplier?] = [];

          if (resp.data.data && resp.data.data.length > 0) {
            const _regBase = resp.data.data.sort(sortBy("codigo.value", "documentFornec.value"))

            for (let reg of _regBase) {
              const _item = {
                id: reg.guidcontrol.value,
                codigo: reg.codigo.value ? reg.codigo.value.padStart(4, '0') : '0000',
                nome: reg.nome.value,
                documento: reg.documento.value,
                codigoFornec: reg.codigoFornec.value ? reg.codigoFornec.value.padStart(6, '0') : '000000',
                documentFornec: reg.documentFornec.value,
                // nomeFornec: reg.nomeFornec.value,
                nomeFornec: `${reg.nomeFornec.value} (${reg.codigoFornec.value})`,
                codClientePrinc: reg.codClientePrinc.value ? reg.codClientePrinc.value.padStart(4, '0') : '0000',
                codFornecPrinc: reg.codFornecPrinc.value ? reg.codFornecPrinc.value.padStart(6, '0') : '000000',
                valorBase: Number(reg.valorBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                qtdeBase: Number(reg.qtdeBase.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                metaMix: Number(reg.metaMix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                mix: Number(reg.mix.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                percentMix: Number(reg.metaMix.value ? (Number(reg.mix.value) / Number(reg.metaMix.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),

                metaFaturamento: Number(reg.metaFaturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                faturamento: Number(reg.faturamento.value).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                percentFaturmento: Number(reg.metaFaturamento.value ? (Number(reg.faturamento.value) / Number(reg.metaFaturamento.value)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                criadoEm: momenttz(reg.criadoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                alteradoEm: momenttz(reg.alteradoEm.value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm'),
                metaMixPrincipal: Number(reg.metaMixPrincipal.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                mixPrincipal: Number(reg.mixPrincipal.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                metaMixFornPrincipal: Number(reg.metaMixFornPrincipal.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
                mixFornPrincipal: Number(reg.mixFornPrincipal.value).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0'),
              }

              if (validFilters(_item, params)) {
                _arrayReg.push(_item);

                let _aux = _arrayResume.find(el => el?.codClientePrinc === _item.codClientePrinc && el?.codFornecPrinc === _item.codFornecPrinc);
                if (_aux) {
                  const _index = _arrayResume.indexOf(_aux);

                  let _newReg: IHomeTargetsSupplier = {
                    id: _aux.id,
                    codigo: _aux.codigo,
                    nome: _aux.nome,
                    documento: _aux.documento,
                    codigoFornec: _aux.codigoFornec,
                    documentFornec: _aux.documentFornec,
                    nomeFornec: _aux.nomeFornec,
                    codClientePrinc: reg.codClientePrinc.value ? reg.codClientePrinc.value.padStart(4, '0') : '0000',
                    codFornecPrinc: reg.codFornecPrinc.value ? reg.codFornecPrinc.value.padStart(6, '0') : '000000',
                    valorBase: _aux.valorBase,
                    qtdeBase: _aux.qtdeBase,
                    metaMix: _aux.metaMix,
                    mix: _aux.mix,
                    percentMix: _aux.metaMix,
                    metaFaturamento: _aux.metaFaturamento,
                    faturamento: _aux.faturamento,
                    percentFaturmento: _aux.metaFaturamento,
                    criadoEm: _aux.criadoEm,
                    alteradoEm: _aux.alteradoEm,
                  }

                  const _auxValorBase = convertToNumber(_aux.valorBase);
                  const _valorBase = convertToNumber(_item.valorBase);
                  const _novoValorBase = Number(_auxValorBase) + Number(_valorBase);
                  _newReg.valorBase = Number(_novoValorBase).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  const _auxQtdeBase = convertToNumber(_aux.qtdeBase);
                  const _qtdeBase = convertToNumber(_item.qtdeBase);
                  const _novoQtdeBase = Number(_auxQtdeBase) + Number(_qtdeBase);
                  _newReg.qtdeBase = Number(_novoQtdeBase).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  const _novoMetaMix = convertToNumber(_item.metaMixPrincipal) // Number(_auxMetaMix) + Number(_metaMix);
                  _newReg.metaMix = Number(_novoMetaMix).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0');

                  const _novoMix = convertToNumber(_item.mixPrincipal); // Number(_auxMix) + Number(_mix);
                  _newReg.mix = Number(_novoMix).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0');
                  _newReg.percentMix = Number(_novoMetaMix ? (_novoMix / _novoMetaMix) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  _newReg.metaFaturamento = Number(convertToNumber(_aux.metaFaturamento) + convertToNumber(_item.metaFaturamento)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _newReg.faturamento = Number(convertToNumber(_aux.faturamento) + convertToNumber(_item.faturamento)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _newReg.percentFaturmento = Number(convertToNumber(_aux.metaFaturamento) > 0 ? (convertToNumber(_aux.faturamento) / convertToNumber(_aux.metaFaturamento)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  if (_item.codigo === _item.codClientePrinc &&
                    _aux.codigo !== _aux.codClientePrinc) {

                    _newReg.codigo = _item.codigo;
                    _newReg.documento = _item.documento;
                    _newReg.nome = _item.nome;
                  }

                  if (_item.codigoFornec === _item.codFornecPrinc &&
                    _aux.codigoFornec !== _aux.codFornecPrinc) {

                    _newReg.codigoFornec = _item.codigoFornec;
                    _newReg.documentFornec = _item.documentFornec;
                    _newReg.nomeFornec = _item.nomeFornec;
                  }

                  _arrayResume[_index] = _newReg;

                }
                else {
                  _arrayResume.push(_item);
                }

                let _auxProvider = _arrayResumeProvider.find(el => el?.codigo === _item.codigo && el?.codFornecPrinc === _item.codFornecPrinc);
                if (_auxProvider) {
                  const _index = _arrayResumeProvider.indexOf(_auxProvider);

                  let _newReg: IHomeTargetsSupplier = {
                    id: _auxProvider.id,
                    codigo: _auxProvider.codigo,
                    nome: _auxProvider.nome,
                    documento: _auxProvider.documento,
                    codigoFornec: _auxProvider.codigoFornec,
                    documentFornec: _auxProvider.documentFornec,
                    nomeFornec: _auxProvider.nomeFornec,
                    codClientePrinc: reg.codClientePrinc.value ? reg.codClientePrinc.value.padStart(4, '0') : '0000',
                    codFornecPrinc: reg.codFornecPrinc.value ? reg.codFornecPrinc.value.padStart(6, '0') : '000000',
                    valorBase: _auxProvider.valorBase,
                    qtdeBase: _auxProvider.qtdeBase,
                    metaMix: _auxProvider.metaMix,
                    mix: _auxProvider.mix,
                    percentMix: _auxProvider.metaMix,
                    metaFaturamento: _auxProvider.metaFaturamento,
                    faturamento: _auxProvider.faturamento,
                    percentFaturmento: _auxProvider.metaFaturamento,
                    criadoEm: _auxProvider.criadoEm,
                    alteradoEm: _auxProvider.alteradoEm,
                  }

                  const _auxValorBase = convertToNumber(_auxProvider.valorBase);
                  const _valorBase = convertToNumber(_item.valorBase);
                  const _novoValorBase = Number(_auxValorBase) + Number(_valorBase);
                  _newReg.valorBase = Number(_novoValorBase).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  const _auxQtdeBase = convertToNumber(_auxProvider.qtdeBase);
                  const _qtdeBase = convertToNumber(_item.qtdeBase);
                  const _novoQtdeBase = Number(_auxQtdeBase) + Number(_qtdeBase);
                  _newReg.qtdeBase = Number(_novoQtdeBase).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  const _novoMetaMix = convertToNumber(_item.metaMixFornPrincipal) // Number(_auxMetaMix) + Number(_metaMix);
                  _newReg.metaMix = Number(_novoMetaMix).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0');

                  const _novoMix = convertToNumber(_item.mixFornPrincipal); // Number(_auxMix) + Number(_mix);
                  _newReg.mix = Number(_novoMix).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).padStart(4, '0');
                  _newReg.percentMix = Number(_novoMetaMix ? (_novoMix / _novoMetaMix) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  _newReg.metaFaturamento = Number(convertToNumber(_auxProvider.metaFaturamento) + convertToNumber(_item.metaFaturamento)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _newReg.faturamento = Number(convertToNumber(_auxProvider.faturamento) + convertToNumber(_item.faturamento)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                  _newReg.percentFaturmento = Number(convertToNumber(_auxProvider.metaFaturamento) > 0 ? (convertToNumber(_auxProvider.faturamento) / convertToNumber(_auxProvider.metaFaturamento)) * 100 : 0).toLocaleString("pt-BR", { minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2 });

                  if (_item.codigo === _item.codClientePrinc &&
                    _auxProvider.codigo !== _auxProvider.codClientePrinc) {

                    _newReg.codigo = _item.codigo;
                    _newReg.documento = _item.documento;
                    _newReg.nome = _item.nome;
                  }

                  if (_item.codigoFornec === _item.codFornecPrinc &&
                    _auxProvider.codigoFornec !== _auxProvider.codFornecPrinc) {

                    _newReg.codigoFornec = _item.codigoFornec;
                    _newReg.documentFornec = _item.documentFornec;
                    _newReg.nomeFornec = _item.nomeFornec;
                  }

                  _arrayResumeProvider[_index] = _newReg;

                }
                else {
                  _arrayResumeProvider.push(_item);
                }
              }
            }
          }

          regReturn.list = _arrayReg;
          regReturn.listResume = _arrayResume;
          regReturn.listResumeProvider = _arrayResumeProvider;
          resolve(regReturn);
        })
        .catch(error => {
          console.log(error);
          let msgerror = error.stack ? error.stack : 'Ocorreu um erro!';
          if (error.response && error.response.data) {
            msgerror = error.response.data.msgErr;
          }
          if (error.response && error.response.result) {
            msgerror = error.response.result.msgErr;
          }

          regReturn.status = '400';
          regReturn.message = msgerror;
          regReturn.msgErro = JSON.stringify(error);
          reject(regReturn)
        });
    }
    catch (err) {
      console.log('AssociatesReport.Service.getAll.err');
      console.log(err);

      let _msgError = 'Ocorreu um erro!';
      regReturn.status = '400';
      regReturn.message = _msgError;
      regReturn.msgErro = JSON.stringify(err);
      reject(regReturn)
    }
  });
}